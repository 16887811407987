import React from "react";
import DetailItem from "../Layouts/DetailItem";

export default function DetailPages() {
  return (
    <div>
      <DetailItem />
    </div>
  );
}
